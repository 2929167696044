import {
    BahrainFlag,
    InternationalFlag,
    KuwaitFlag, OmanFlag,
    QatarFlag,
    SaudiFlag,
    UAEFlag
} from "../assets/icons/constants/flags";
import React from "react";

export const LANGUAGES_CODES = {
    AR: 'ar',
    EN: 'en'
}

export const CURRENCIES = {
    AED: 'AED',
    SAR: 'SAR',
    QAR: 'QAR',
    KWD: 'KWD',
    USD: 'USD',
    BHD: 'BHD',
    OMR: 'OMR'
}

export const arabicAndEnglish = [LANGUAGES_CODES.AR.toUpperCase(), LANGUAGES_CODES.EN.toUpperCase()]

export const countries = [
    {
        id: 'SA',
        name: 'KSA',
        code: 'SA',
        languages: arabicAndEnglish,
        currency: CURRENCIES.SAR,
        FlagComponent: <SaudiFlag height={24} width={24}/>,
    },
    {
        id: 'AE',
        name: 'UAE',
        code: 'AE',
        languages: arabicAndEnglish,
        currency: CURRENCIES.AED,
        FlagComponent: <UAEFlag height={24} width={24}/>,
    },
    {
        id: 'QA',
        name: 'QATAR',
        code: 'QA',
        languages: arabicAndEnglish,
        currency: CURRENCIES.QAR,
        FlagComponent: <QatarFlag height={24} width={24}/>,
    },
    {
        id: 'BH',
        name: 'BAHRAIN',
        code: 'BH',
        languages: arabicAndEnglish,
        currency: CURRENCIES.BHD,
        FlagComponent: <BahrainFlag height={24} width={24}/>,
    },
    {
        id: 'OM',
        name: 'OMAN',
        code: 'OM',
        languages: arabicAndEnglish,
        currency: CURRENCIES.OMR,
        FlagComponent: <OmanFlag height={24} width={24}/>,
    },
    {
        id: 'KW',
        name: 'KUWAIT',
        code: 'KW',
        languages: arabicAndEnglish,
        currency: CURRENCIES.KWD,
        FlagComponent: <KuwaitFlag height={24} width={24}/>,
    },
    {
        id: 'INT',
        name: 'OTHERS',
        code: 'INT',
        languages: arabicAndEnglish,
        currency: CURRENCIES.USD,
        FlagComponent: <InternationalFlag height={24} width={24}/>,
    }
];

export const languageData = {
    [LANGUAGES_CODES.EN]: {
        language: LANGUAGES_CODES.AR.toUpperCase(),
        code: LANGUAGES_CODES.AR,
        direction: "ltr",

    },
    [LANGUAGES_CODES.AR]: {
        language: LANGUAGES_CODES.EN.toUpperCase(),
        code: LANGUAGES_CODES.EN,
        direction: "rtl",

    },
};
